* { padding: 0; margin: 0; }
html, body, #fullheight {
    min-height: 100% !important;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
}

.membership {
    grid-column: 1 / 3;
    grid-row: 1 / 5;
}
.prayertime {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
}
.clock-logo {
    grid-column: 4 / 5;
    grid-row: 1 / 3;
    text-align: center;
    padding-top: 4vh;
}
.info-page1 {
    grid-column: 3 / 6;
    grid-row: 3 / 5;
    padding: 2vh;
}
.announcement{
    grid-column: 1 / 6;
    grid-row: 1 / 3;
}
.flyer1{
    grid-column: 1 / 2;
    grid-row: 3 / 5;
    margin: auto;
}
.flyer2{
    grid-column: 2 / 3;
    grid-row: 3 / 5;
    margin: auto;
}
.flyer3{
    grid-column: 3 / 4;
    grid-row: 3 / 5;
    margin: auto;
}
.flyer4{
    grid-column: 4 / 5;
    grid-row: 3 / 5;
    margin: auto;
}

img {
    max-width: 100%;
    max-height: 100%;
}

table {
    width: 100%;
    border-spacing: 0px;
    font-size: 1.1em;
}

th {
    padding: 0.4em;
}

td {
    padding: 0.4em;
    text-align: center;
    min-width: 10%;
}

tr {
    padding: 0.4em;
}

.align-left {
    text-align: left;
}

.secondrow {
    background-color: #eaeef1;
}

h1 {
    padding: 0px;
    margin: 0px;
}

h2 {
    padding: 0px;
    margin: 0px;
    font-weight: 300;
    font-size: 1.3em;
}

.content {
    padding: 2em;
}

p{
    margin: 0;
}

.center {
    text-align: center;
}

strong {
    font-weight: 800;
    font-size: 1.2em;
}

.prayertime {
    height: 100%;
}

.times {
    padding-top: 1vh;
}

.time {
    height: 6.5vh;
    position: relative;
}

.time {
    background-color: white;
    margin-bottom: 0.2vh;
    margin-top: 0.2vh;
    margin-left: 5vw;
    margin-right: 5vw;
}

.time-content {
    padding: 1vh;
    padding-left: 2vh;
}

.prayer-name {
    font-size: 1.5vh;
    font-weight: 700;
}

.prayer-time {
    font-size: 2.5vh;
    font-weight: 300;
}

.selected .prayer-name{
    font-size: 2vh;
    font-weight: 700;
}

.selected .prayer-time{
    font-size: 4.5vh;
    font-weight: 300;
}

.selected {
    height: 10vh;
    background-color: #49b295;
    color: white;
    margin-left: 4vw;
    margin-right: 4vw;
    margin-bottom: -1px;
    margin-top: -1px;
}

.prayertime img{
    height: auto;
    width: 3vw;
    position: absolute;
    right: 1vw;
    bottom: 0;
    top: 0;
    margin: auto;
}

.prayertime .selected img{
    width: 4vw;
    right: 2vw;
}

.fullcenter-content {
    text-align: center;
}

.reset-button {
    font-size: 3vh!important;
}

.home {
    text-align: center;
    padding-top: 4vh;
    font-size: 4vh!important;
}

.prayertime-info {
    text-align: center;
    font-size: 1em;
    padding-bottom: 1vh;
    font-weight: 500;
}

.timerCircle {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
}

.clock_logo {
    margin: 20%;
}

.page2-logo{
    position: fixed;
    height: 5vh;
    right: 5vh;
    top: 4vh;
}